import { PageProps } from 'gatsby'
import styled from 'styled-components'

import { Accent, TitleLarge } from '../../common/styles'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import Layout from '../../layouts'

import { Container } from './LegalSection.styles'

const Title = styled(TitleLarge)`
  margin-top: 28px;
  padding-right: 7px;
  padding-left: 7px;
`

const LegalText = styled.div`
  padding: 0 21px;
  margin-bottom: 35px;
`

const PrestationsSection = ({
  data: { strapiGlobal }
}: Pick<PageProps<Queries.LegalQuery>, 'data'>) => {
  const text = strapiGlobal?.legal?.data?.legal!

  return (
    <Layout smallNavBar>
      <Container>
        <Title>
          Mentions <Accent>Légales</Accent>
        </Title>
        <LegalText>
          <MarkdownWrapper>{text}</MarkdownWrapper>
        </LegalText>
      </Container>
    </Layout>
  )
}

export default PrestationsSection
