import { graphql, PageProps } from 'gatsby'

import LegalSection from '../sections/LegalSection'

const PrestationsIndex = ({ data }: PageProps<Queries.LegalQuery>) => {
  return <LegalSection data={data} />
}

export default PrestationsIndex

export const query = graphql`
  query Legal {
    strapiGlobal {
      legal {
        data {
          legal
        }
      }
    }
  }
`
